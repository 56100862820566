import React, { useEffect } from "react";
const App = () => {
  useEffect(() => {
    window.location.replace("https://zocket.ai/");
  }, []);

  return <div className="app"></div>;
};

export default App;
